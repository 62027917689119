import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  id: "kt_aside",
  class: "aside aside-hoverable aside-light",
  "data-kt-drawer": "true",
  "data-kt-drawer-name": "aside",
  "data-kt-drawer-activate": "{default: true, lg: false}",
  "data-kt-drawer-overlay": "true",
  "data-kt-drawer-width": "{default:'200px', '300px': '250px'}",
  "data-kt-drawer-direction": "start",
  "data-kt-drawer-toggle": "#kt_aside_mobile_toggle"
}
const _hoisted_2 = {
  class: "aside-logo flex-column-auto",
  id: "kt_aside_logo"
}
const _hoisted_3 = {
  key: 0,
  href: "dash"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  href: "dash"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  id: "kt_aside_toggle",
  class: "btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle",
  "data-kt-toggle": "true",
  "data-kt-toggle-state": "active",
  "data-kt-toggle-target": "body",
  "data-kt-toggle-name": "aside-minimize"
}
const _hoisted_8 = { class: "svg-icon svg-icon-1 rotate-180" }
const _hoisted_9 = { class: "aside-menu flex-column-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTMenu = _resolveComponent("KTMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.asideTheme === 'dark')
        ? (_openBlock(), _createElementBlock("a", _hoisted_3, [
            _createElementVNode("img", {
              alt: "Logo",
              src: _ctx.darkLogo,
              class: "h-40px logo"
            }, null, 8, _hoisted_4)
          ]))
        : _createCommentVNode("", true),
      (_ctx.asideTheme === 'light')
        ? (_openBlock(), _createElementBlock("a", _hoisted_5, [
            _createElementVNode("img", {
              alt: "Logo",
              src: _ctx.lightLogo,
              class: "h-40px logo"
            }, null, 8, _hoisted_6)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("span", _hoisted_8, [
          _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr080.svg" })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_KTMenu)
    ])
  ]))
}